import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { ImageUpload } from 'src/app/shared/models/general.models'

@Component({
	selector: 'app-product-slider-cropper',
	templateUrl: './slider-product-cropper.component.html',
	styleUrls: ['./slider-product-cropper.component.scss'],
})
export class SliderCropperComponent {
	croppedImage: any = { croppedMobile: '' }
	step = 1
	constructor(
		public dialogRef: MatDialogRef<SliderCropperComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			imageUpload: { mobile: ImageUpload }
			imageChangedEvent: string
		}
	) {}
	imageCropped(event: ImageCroppedEvent, type: string) {
		this.croppedImage[type] = event.base64
	}
	cancel() {
		this.croppedImage = null
	}
}
