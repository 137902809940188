import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog'
import { ImageUpload } from 'src/app/shared/models/general.models'
import { SliderCropperComponent } from '../slider-cropper/slider-product-cropper.component'

@Component({
	selector: 'app-slider-uploader',
	templateUrl: './product-slider-uploader.component.html',
	styleUrl: './product-slider-uploader.component.scss',
})
export class SliderUploaderComponent {
	accetpFiles = 'image/png, image/jpeg, image/jpg'
	imageChangedEvent: any = ''
	croppedImage: { croppedCover: string; croppedMobile: string } = {
		croppedCover: '',
		croppedMobile: '',
	}
	uploadedFile: { croppedCover: File | null; croppedMobile: File | null } = {
		croppedCover: null,
		croppedMobile: null,
	}
	sizeError = false
	typeError = false
	@ViewChild('imgInput', { static: false }) fileInput!: ElementRef
	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<SliderUploaderComponent>,
		@Inject(MAT_DIALOG_DATA)
		public imageUpload: { cover: ImageUpload; mobile: ImageUpload }
	) {}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		if (
			this.imageChangedEvent?.target?.files[0]?.size <= 2000000 &&
			this.accetpFiles.includes(this.imageChangedEvent.target.files[0].type)
		) {
			this.sizeError = false
			const dialogRef = this.dialog.open(SliderCropperComponent, {
				width: '500px',
				panelClass: 'overflow-y-auto',
				data: {
					imageUpload: this.imageUpload,
					imageChangedEvent: this.imageChangedEvent,
				},
			})

			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.croppedImage = result
					this.fileRender()
				} else {
					this.fileInput.nativeElement.value = null
					this.deleteImage()
				}
			})
		} else {
			this.imageChangedEvent?.target?.files[0]?.size <= 2000000
				? null
				: (this.sizeError = true)
			this.accetpFiles.includes(this.imageChangedEvent.target.files[0].type)
				? null
				: (this.typeError = true)
		}
	}
	resetFileInput() {
		this.fileInput.nativeElement.value = null
	}
	fileRender() {
		// this.uploadedFile.croppedCover = new File(
		// 	[this.dataURItoBlob(this.croppedImage.croppedCover)],
		// 	'image.jpg'
		// )

		this.uploadedFile.croppedMobile = new File(
			[this.dataURItoBlob(this.croppedImage.croppedMobile)],
			'image.jpg'
		)
	}

	dataURItoBlob(dataURI: any): Blob {
		const byteString = atob(dataURI.split(',')[1])
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
		const ab = new ArrayBuffer(byteString.length)
		let ia = new Uint8Array(ab)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: mimeString })
	}

	deleteImage() {
		// this.croppedImage = { croppedCover: '', croppedMobile: '' }
		// this.imageChangedEvent = ''
		// this.uploadedFile = { croppedCover: null, croppedMobile: null }
	}
}
